import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    {
      staticClass: "fill-height justify-center",
      staticStyle: { "margin-top": "45px" },
      attrs: { id: "register", tag: "section" },
    },
    [
      _c(
        VSlideYTransition,
        { attrs: { appear: "" } },
        [
          _c(
            VCard,
            {
              staticClass: "px-5 py-3",
              staticStyle: { margin: "20px auto", display: "block" },
              attrs: {
                color: "white",
                light: "",
                "max-width": "100%",
                width: "700",
              },
            },
            [
              _c("div", { staticClass: "pa-2" }, [
                _c(
                  "h1",
                  {
                    staticClass: "text-center",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("account_validation")) + " ")]
                ),
                _vm.alert
                  ? _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.$t(_vm.alert))),
                    ])
                  : _vm.validating
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("validating_account"))),
                        ]),
                        _c(
                          VRow,
                          {
                            staticClass: "my-2",
                            attrs: { justify: "center", align: "center" },
                          },
                          [
                            _c(VProgressCircular, {
                              attrs: { indeterminate: "", size: "15" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }